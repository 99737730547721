import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {},
})

const TitleDescriptionWithLogoAndSection = (props) => {
  const classes = useStyles(props.blok)
  return (
    <SbEditable content={props.blok}>
      <div>{props.blok.title}</div>
      <p>{props.blok.description}</p>
    </SbEditable>
  )
}

export default TitleDescriptionWithLogoAndSection
